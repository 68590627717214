import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { filterImages, findImage } from "../../utils/filterImages.util";
import useIsMobile from "../../utils/useIsMobile.util";
import { indexSlider } from "../../statics/indexSlider.static";

const HeroContainer = styled.section`
  background: ${({ theme }) => theme.lightBlue};
  &,
  & .container {
    position: relative;
  }
  .slick-dots {
    bottom: 0.5rem;
  }
  .slick-next {
    right: 2rem;
  }
  .slick-prev {
    left: 2rem;
    z-index: 1;
  }
  @media (max-width: 992px) {
    margin-bottom: 3rem;

    .slick-dots {
      bottom: -2rem;
    }
  }
`;

const HeroInsideContainer = styled.div`
  /* display: flex !important; */
  position: relative;
`;

const HeroLeftContainer = styled.div`
  width: 100%;
  color: white;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 3.6rem;
  max-width: 1170px;
  left: 50%;
  transform: translate3d(0, 0, 0) translateX(-50%);
  padding-left: 30px;
  .text {
    font-size: 2rem;
  }
  .lead {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 2rem;
  }
  p {
    max-width: 50%;
    float: left;
  }
  @media (max-width: 1200px) {
    p {
      padding: 0 4rem;
    }
  }
  @media (max-width: 992px) {
    &,
    p {
      max-width: 100%;
    }
    .lead {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .text {
      font-size: 1.4rem;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
  @media (max-width: 500px) {
    .lead {
      font-size: 1.8rem;
    }
    .text {
      font-size: 1.2rem;
    }
  }
`;

const IndexPageHero = ({ data }) => {
  const sliderImages = filterImages(data, "indexslider");
  const mobileSliderImages = filterImages(data, "mobil-indexslider");
  const windowSize = useIsMobile();
  const [images, setImages] = useState(sliderImages);

  useEffect(() => {
    if (windowSize) setImages(mobileSliderImages);
    else setImages(sliderImages);
  }, [windowSize]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
  };

  return (
    <HeroContainer>
      <Slider {...sliderSettings}>
        {indexSlider.map((node, i) => (
          <HeroInsideContainer key={`slide-${i}`}>
            <GatsbyImage
              image={getImage(findImage(images, node.image))}
              alt=""
            />
            <HeroLeftContainer>
              <p className="lead">{node.lead}</p>
              <p className="text">{node.text}</p>
            </HeroLeftContainer>
          </HeroInsideContainer>
        ))}
      </Slider>
    </HeroContainer>
  );
};

export default IndexPageHero;

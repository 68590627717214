import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled, { css } from "styled-components";
import {
  doorsInfo,
  ledgesInfo,
  windowsInfo,
} from "../../statics/indexTab.static";
import { filterImages, findImage } from "../../utils/filterImages.util";
import Button from "../General/Button.component";
import {forEach} from "lodash";
import Link from "gatsby-link";

const IndexPageTabContentBox = styled.div`
  margin: 3rem 0;
  background: white;
  display: grid;
  grid-template-columns: 2fr 8fr 3fr;
  transition: all 0.5s ease;
  width: 100%;
  &:hover {
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    grid-auto-rows: 1fr auto;
    grid-template-columns: 1fr 2fr 4fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: 4fr 1fr 4fr;
  }
`;
const IndexPageTabContentBoxLabel = styled.div`
  height: 22rem;
  width: 0;
  text-align: center;
  position: relative;
  ${(props) =>
    props.labelColor &&
    css`
      margin-right: 5rem;
      width: 5rem;
    `}
  background: ${({ theme, labelColor }) => theme[labelColor] || labelColor};
  div {
    position: absolute;
    transform: rotate(-90deg);
    transform-origin: bottom;
    height: 5rem;
    width: 20rem;
    left: -100%;
    top: 25%;
    display: grid;
    place-items: center;
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    letter-spacing: 0.15rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;

const IndexPageTabContentBoxImage = styled.div`
  padding: 1rem 0;
  display: grid;
  place-items: center;
`;

const IndexPageTabContentBoxDesc = styled.div`
  margin-left: 6rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  color: ${(props) => props.theme.darkGray};
  @media (max-width: 500px) {
    text-align: center;
    width: 100%;
    grid-column: span 2;
    margin: 0;
    padding: 0 15px;
  }
`;

const IndexPageTabContentBoxDescTitle = styled.p`
  font-size: 3rem;
  font-family: "Rubik", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  margin-bottom: 1rem;
  line-height: 1;
  span {
    color: ${(props) => props.theme.darkGray};
    text-transform: capitalize;
    font-weight: normal;
    font-size: 2.3rem;
    line-height: 1;
  }
  small {
    font-weight: normal;
    text-transform: none;
    line-height: 1;
    font-size: 1.4rem;
    color: ${(props) => props.theme.darkGray};
  }
`;

const IndexPageTabContentBoxDescMid = styled.div`
  margin-bottom: 1rem;
  @media (max-width: 500px) {
    text-align: center;
    width: 100%;
  }

`;

const IndexPageTabContentBoxDescMidShutter = styled.span`
  color: ${(props) => props.theme.red};
  font-weight: 900;
`;


const IndexPageTabContentBoxDescColors = styled.div`
  display: flex;
  align-items: center;
`;

const IndexPageTabContentBoxDescColorItem = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.3rem;
  background: ${({ customColor }) => customColor};
  margin: 0 0.5rem;
  &:first-of-type {
    margin-left: 1rem;
  }
`;

const IndexPageTabContentBoxPriceWrapper = styled.div`
  justify-self: flex-end;
  background: ${({ theme, customColor }) =>
    theme[customColor] || theme.lightBlue};
  padding: 3rem;
  display: grid;
  place-items: center;
  color: white;
  text-align: center;
  gap: 1rem;
  p  {
    font-weight: 900;
    font-size: 3.6rem;
  }
  @media (max-width: 768px) {
    grid-column: span 3;
    width: 100%;
  }
`;

const IndexPageTabContentContainer = styled.div`
  text-align: center;
  padding-bottom: 3rem;
`;

const IndexPageTabContent = ({ data, activeTab }) => {
  const mapThrough =
    activeTab === 0 ? windowsInfo : activeTab === 1 ? doorsInfo : ledgesInfo;
  const imgThrough =
    activeTab === 0
      ? filterImages(data, "indexwindow")
      : activeTab === 1
      ? filterImages(data, "indexdoor")
      : filterImages(data, "indexledge");
  return (
    <IndexPageTabContentContainer>
      {mapThrough.map((wi, i) => (
        <IndexPageTabContentBox key={i} labeled={wi?.labeled?.isLabeled}>
          <IndexPageTabContentBoxImage>
            <GatsbyImage
                image={getImage(findImage(filterImages(data, "F"), wi.image))}
                alt=""
            />
          </IndexPageTabContentBoxImage>
          <IndexPageTabContentBoxDesc>
            <IndexPageTabContentBoxDescTitle>
              {wi.title} <span>{wi.subTitle}</span><br/><small>{wi.type} / {wi.size}</small>
            </IndexPageTabContentBoxDescTitle>
            <IndexPageTabContentBoxDescMid>
              - Bontással és beépítéssel<br/>
              - Műanyag párkánnyal<br/>
              - Takarólécezéssel<br/>
              <br/>
              <div>
                Redőny felár: <IndexPageTabContentBoxDescMidShutter>+ {wi.priceStuff.shutter}</IndexPageTabContentBoxDescMidShutter>
              </div>
            </IndexPageTabContentBoxDescMid>
            {/* <IndexPageTabContentBoxDescColors>
              További elérhető színek
              {wi.colors.map((color, i) => (
                <IndexPageTabContentBoxDescColorItem
                  key={`color-${i}`}
                  customColor={color}
                />
              ))}
            </IndexPageTabContentBoxDescColors> */}
          </IndexPageTabContentBoxDesc>
          <IndexPageTabContentBoxPriceWrapper
            customColor={wi.priceStuff.priceColor}
          >
            <p>{wi.priceStuff.price}</p>
            {wi.priceStuff.priceDesc}
            <Link
              to={'/kapcsolat?type='+wi.image}
            >
              <Button
                  styleO={{ bg: "lightBlue" }}>
              Érdekel</Button>
            </Link>
          </IndexPageTabContentBoxPriceWrapper>
        </IndexPageTabContentBox>
      ))}
    </IndexPageTabContentContainer>
  );
};

export default IndexPageTabContent;

import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/General/Layout.component";
import IndexPageAbout from "../components/Index/IndexPageAbout.component";
import IndexPageAboutCarousel from "../components/Index/IndexPageAboutCarousel.component";
import IndexPageCatalogs from "../components/Index/IndexPageCatalogs.component";
import IndexPageCTA from "../components/Index/IndexPageCTA.component";
import IndexPageHero from "../components/Index/IndexPageHero.component";
import IndexPageTab from "../components/Index/IndexPageTab.component";
import { indexPage } from "../statics/pageInfo.static";
import { genPageInfo } from "../utils/genPageInfo.util";

const IndexPage = ({ data }) => {
  return (
    <div>
      {genPageInfo(indexPage)}
      <Layout>
        <IndexPageHero data={data.allFile.edges} />
        <IndexPageAbout />
        <IndexPageAboutCarousel />
        <IndexPageTab data={data.allFile.edges} />
        <IndexPageCTA />
        {/* <IndexPageCatalogs data={data.allFile.edges} /> */}
      </Layout>
    </div>
  );
};

export const imgQuery = graphql`
  {
    allFile(
      filter: {
        extension: {}
        dir: {
          regex: "/indexHero|indexWindow|indexDoor|indexLedge|indexCatalog/"
        }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
  }
`;

export default IndexPage;

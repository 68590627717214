import React, { useState } from "react";
import styled from "styled-components";
import IndexPageTabContent from "./IndexPageTabContent.component";
import IndexPageTabSelector from "./IndexPageTabSelector.component";

const IndexPageTabContainer = styled.section`
  background: ${({ theme }) => theme.lighterGray};
`;

const IndexPageTab = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);
  const activeSetter = (id) => {
    setActiveTab(id);
  };

  return (
    <IndexPageTabContainer>
      <div className="container">
        <IndexPageTabSelector
          activeTab={activeTab}
          activeTabSetter={activeSetter}
        />
        <IndexPageTabContent data={data} activeTab={activeTab} />
      </div>
    </IndexPageTabContainer>
  );
};

export default IndexPageTab;

export const indexSlider = [
  {
    lead: "Nyílászárók gyártása",
    text: "Kiváló minőségű alapanyagok felhasználásával dolgozunk.",
    image: "1",
  },
  {
    lead: "tervezés, gyártás, beépítés",
    text: "Minden munkafolyamatot precízen, a lehető legmagasabb szakértelemmel végzünk el az ön számára.",
    image: "2",
  },
  {
    lead: "Nyílászárók gyártása",
    text: "Kiváló minőségű alapanyagok felhasználásával dolgozunk.",
    image: "1",
  },
];

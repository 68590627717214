import React from "react";
import styled from "styled-components";

const IndexPageTabSelectorContainer = styled.div`
  padding-top: 6rem;
`;
const IndexPageTabSelectorWrapper = styled.ul`
  display: flex;
`;
const IndexPageTabSelectorItem = styled.li`
  margin-right: 2rem;
  padding: 1rem 0;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 900;
  transition: all 0.5s ease;
  border-bottom: 5px solid transparent;
  cursor: pointer;
  &.active,
  &:hover {
    border-bottom: 5px solid ${({ theme }) => theme.darkBlue};
  }
  @media (max-width: 992px) {
    font-size: 1.4rem;
  }
`;

const IndexPageTabSelector = ({ activeTab, activeTabSetter }) => {
  const tabs = ["Ablakok"];
  return (
    <IndexPageTabSelectorContainer id='ajanlataink'>
      <IndexPageTabSelectorWrapper>
        {tabs.map((tab, i) => (
          <IndexPageTabSelectorItem
            key={`tab${i}`}
            className={`${i === activeTab ? "active" : ""}`}
            onClick={() => activeTabSetter(i)}
          >
            {tab}
          </IndexPageTabSelectorItem>
        ))}
      </IndexPageTabSelectorWrapper>
    </IndexPageTabSelectorContainer>
  );
};

export default IndexPageTabSelector;

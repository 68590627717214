export const windowsInfo = [
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Bukó-Nyíló Ablak",
    size: "1200 mm x 1500 mm",
    priceStuff: {
      price: "134 100 Ft",
      shutter: "39 800 Ft"
    },
    image: "F01",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Egy szárnyú bukó-nyíló Ablak",
    size: "1500 mm x 1500 mm",
    priceStuff: {
      price: "152 500 Ft",
      shutter: "50 800 Ft"
    },
    image: "F03",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Középen felnyíló nyíló-bukó-nyíló Ablak",
    size: "1500 mm x 1500 mm",
    priceStuff: {
      price: "195 000 Ft",
      shutter: "50 800 Ft"
    },
    image: "F26",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Aszimmetrikus tokosztótt bukó-nyíló-bukó-nyíló Ablak",
    size: "2100 mm x 1500 mm",
    priceStuff: {
      price: "241 500 Ft",
      shutter: "82 200 Ft"
    },
    image: "F05",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Osztott bukó-nyíló - bukó-nyíló - bukó-nyíló Ablak",
    size: "2100 mm x 1500 mm",
    priceStuff: {
      price: "294 800 Ft",
      shutter: "82 200 Ft"
    },
    image: "F30",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Bukó-Nyíló - Fix STADUR - Bukó- Nyíló Ablak",
    size: "2100 mm x 1500 mm",
    priceStuff: {
      price: "271 500 Ft",
      shutter: "82 200 Ft"
    },
    image: "F07",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Bukó-Nyíló - Fix STADUR - Bukó- Nyíló Ablak",
    size: "2700 mm x 1500 mm",
    priceStuff: {
      price: "325 500 Ft",
      shutter: "112 500 Ft"
    },
    image: "F29",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Erkélyfal Ablak",
    size: "2100 mm x 2400 mm",
    priceStuff: {
      price: "334 500 Ft",
      shutter: "86 500 Ft"
    },
    image: "F15",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Erkélyfal Ablak",
    size: "2220 mm x 2645 mm",
    priceStuff: {
      price: "431 500 Ft",
      shutter: "96 500 Ft"
    },
    image: "F18",
  },
  {
    title: "Horizont PS",
    subTitle: "7 légkamrás nyílászáró",
    type: "Erkélyfal Ablak",
    size: "3100 mm x 2645 mm",
    priceStuff: {
      price: "535 500 Ft",
      shutter: "136 500 Ft"
    },
    image: "F19",
  },
];

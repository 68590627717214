import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { catalogs } from "../../statics/indexCatalog.static";
import { filterImages, findImage } from "../../utils/filterImages.util";

const IndexPageCatalogsContainer = styled.section`
  padding: 6rem 0;
  background: ${({ theme }) => theme.lighterGray};
`;

const IndexPageCatalogsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  gap: 1rem;
  & a:nth-child(1) {
    grid-column: 1 / 3;
    grid-row: 1 / 4;
  }
  & a:nth-child(2) {
    grid-column: 3 / 4;
    grid-row: 1 / 3;
  }
  & a:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 4 / 6;
  }
  & a:nth-child(4) {
    grid-column: 2 / 3;
    grid-row: 4 / 6;
  }
  & a:nth-child(5) {
    grid-column: 3 / 4;
    grid-row: 3 / 6;
  }
  img {
    object-fit: cover;
    width: auto;
    height: 100%;
    max-width: 9000px;
  }
  div {
    height: 100%;
  }
`;

const IndexPageCatalogsItem = styled.div`
  position: relative;
  overflow: hidden;
  & img {
    transition: all 0.5s ease !important;
  }
  &:hover img {
    transform: scale(1.1);
  }
  &:before {
    content: attr(data-text);
    z-index: 1;
    color: white;
    font-weight: 900;
    font-size: ${({ fontSize }) => fontSize};
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    display: block;
  }
  &:after {
    content: "+";
    z-index: 1;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    font-size: 2rem;
    display: grid;
    color: white;
    background: ${({ theme }) => theme.darkBlue};
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
    place-items: center;
    transition: all 0.5s ease;
  }
  &:hover:after {
    color: ${({ theme }) => theme.darkBlue};
    background: white;
    transform: rotate(90deg);
  }
  @media (max-width: 768px) {
    &:after {
      display: none;
    }
    &:before {
      font-size: calc(${({ fontSize }) => fontSize} * 0.8);
      bottom: 0.5rem;
      left: 0.5rem;
    }
  }
`;

const IndexPageCatalogsLead = styled.div`
  text-align: center;
  div {
    font-size: 3.6rem;
    font-weight: 900;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  p {
    color: ${({ theme }) => theme.darkGray};
    margin-bottom: 4rem;
  }
  @media (max-width: 992px) {
    div {
      font-size: 2.6rem;
    }
  }
`;

const IndexPageCatalogs = ({ data }) => {
  const images = filterImages(data, "catalog");
  const catalogArray = catalogs;
  return (
    <IndexPageCatalogsContainer>
      <div className="container">
        <IndexPageCatalogsLead>
          <div>Katalógusok</div>
          <p>Válogasson kedvére katalógusainkból.</p>
        </IndexPageCatalogsLead>
        <IndexPageCatalogsWrapper>
          {catalogArray.map((catalog, i) => {
            return (
              <a
                href={catalog.href}
                key={`catalog${i}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IndexPageCatalogsItem
                  data-text={`${catalog.title}`}
                  fontSize={`${catalog.title === "Ablakok" ? "4rem" : "2rem"}`}
                >
                  <GatsbyImage
                    image={getImage(findImage(images, catalog.image))}
                    alt=""
                  />
                </IndexPageCatalogsItem>
              </a>
            );
          })}
        </IndexPageCatalogsWrapper>
      </div>
    </IndexPageCatalogsContainer>
  );
};

export default IndexPageCatalogs;
